<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col-left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader" @change="setParkNull"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <a-operation-select
                  ref="operationSelect"
                  @change="setParkNull"
                ></a-operation-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
                <a-park-type-select ref="parkTypeSelect" @change="setParkNull"></a-park-type-select>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="areaId"
                       filterable
                       placeholder="请选择"
                       @change="areaChange">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in areaList"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')">
            <el-select v-model.trim="streetId"
                       @change="streetChange"
                       filterable
                       placeholder="请选择">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="area.areaName"
                         :value="area.areaId"
                         :key="area.areaId"
                         v-for="area in streetList"></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.park_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Date_search')">
                <a-date-picker ref="datePicker" :selectkeys="selectkeys"></a-date-picker>
              </el-form-item>
              <!-- <el-form-item label="" prop="date">
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              :type="formInline.dateType == 'day' ? 'daterange' : 'monthrange'"
              :valueFormat="formInline.dateType == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
            />
          </el-form-item> -->
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  getData();
                "
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button
                type="info"
                icon="el-icon-delete"
                @click="empty()"
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                >{{ $t('button.reset') }}</el-button
              >
              <!-- <el-button type="success" icon="el-icon-upload2" v-if='$route.meta.authority.button.export'
                       @click="exportFile()">导出
            </el-button> -->
            </div>
          </div>
        </el-form>
      </div>
      <div class="statistical paddingT10 paddingL20 paddingB20 marginB20">
        <div class="flexBetween marginB10">
          <graphTitle
            :title="'停车指标总览'"
            style="margin-bottom: 14px; display: inline-block; width: 90%"
          ></graphTitle>
          <!-- <el-button size="mini"
                           @click='exportFileList'
                           type="primary"
                           v-if="$route.meta.authority.button.export">{{ $t('button.export') }}</el-button> -->
        </div>

        <div class="circular">
          <el-row :gutter="20" class="list">
            <el-col :span="6">
              <div class="grid-content bg-purple graphShadow">
                <div class="parkStatistic parkStatisticbg3">
                  <span>{{ indicators.parkingCnt ? indicators.parkingCnt : 0 }}</span>
                </div>
                <h2>
                  停车记录数(次)
                  <el-tooltip placement="top" content="统计期间内的总停车记录数, 以入场为准">
                    <i class="el-icon-question my-icon" />
                  </el-tooltip>
                </h2>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple graphShadow">
                <div class="parkStatistic parkStatisticbg2">
                  <span>{{
                    indicators.berthTurnover ? indicators.berthTurnover.toFixed(2) : 0.0
                  }}</span>
                </div>
                <h2>
                  日均泊位周转次数(次)
                  <el-tooltip placement="top" content="统计期间内平均每日每泊位的停车记录数">
                    <i class="el-icon-question my-icon" />
                  </el-tooltip>
                </h2>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple graphShadow">
                <div class="parkStatistic parkStatisticbg1">
                  <span>{{
                    indicators.parkUtilizationRatio
                      ? (indicators.parkUtilizationRatio * 100).toFixed(2) + "%"
                      : "0.00%"
                  }}</span>
                </div>
                <h2>
                  停车资源利用率
                  <el-tooltip placement="top" content="统计期间内停车资源使用比例">
                    <i class="el-icon-question my-icon" />
                  </el-tooltip>
                </h2>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple graphShadow">
                <div class="parkStatistic parkStatisticbg4">
                  <span>{{ indicators.avgPakingTime | formatDuringFilter }}</span>
                </div>
                <h2>
                  平均停车时长
                  <el-tooltip placement="top" content="统计期间内入场的停车记录的平均停车时长">
                    <i class="el-icon-question my-icon" />
                  </el-tooltip>
                </h2>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 折线图 -->
      <CircularChart
        v-if="chartDateType == 1"
        class="graphShadow paddingT10 paddingL20"
        :nightParktableData="tableData"
        :dateType="chartDateType"
        :type="type3"
      ></CircularChart>
      <ParkingChartWeek
        v-if="chartDateType == 3"
        class="graphShadow paddingT10 paddingL20"
        :nightParktableData="tableData"
        :dateType="chartDateType"
        :type="type3"
      ></ParkingChartWeek>
      <ParkingChartMonth
        v-if="chartDateType == 2"
        class="graphShadow paddingT10 paddingL20"
        :nightParktableData="tableData"
        :dateType="chartDateType"
        :type="type3"
      ></ParkingChartMonth>
      <div style="margin: 20px 0">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="graphShadow paddingT10 paddingLR20">
              <graphTitle :title="'停车资源利用情况分析'" style="margin-bottom: 14px"></graphTitle>
              <!-- 图表 -->
              <pieChart :arrearageStrip="analysis" :type="type2"></pieChart>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="graphShadow paddingT10 paddingLR20">
              <div class="flexBetween">
                <graphTitle :title="'停车资源利用率排行榜'"></graphTitle>
                <el-button
                  size="mini"
                  @click="exportFileList"
                  type="info"
                  plain
                  v-if="$route.meta.authority.button.export"
                  ><i class="el-icon-upload2"></i>&nbsp;{{ $t('button.export') }}</el-button
                >
              </div>
              <rankingList
                :activeNameTwo="activeName"
                :rankingNumber="rankingNumber"
                :area="areaList"
                :street="streetList"
                @getranking="getranking"
                :parking="ranking"
              ></rankingList>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);
// import timeRangePick from "@/components/timePicker";
import {
  exportExcelNew,
  dateFormat,
  formatDuring,
  //   setIndex
} from "@/common/js/public.js";
import autoComplete from "@/components/autocomplete";
import graphTitle from "@/components/graphTitle";
import rankingList from "./rankingList";
// 饼形图
import pieChart from "./circularChart5";
// 折线图
import CircularChart from "./ParkingChart.vue";
import ParkingChartWeek from "./ParkingChartWeek.vue";
import ParkingChartMonth from "./ParkingChartMonth.vue";
export default {
  name: "parkIncomeDayStatics",
  data() {
    const startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    const endTime = new Date();
    endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      chartDateType: 1,
      selectkeys: ["date", "month", "week", "year"],
      defaultTime: [startTime, endTime],
      activeName: "first",
      rankingNumber: 0,
      areaId: "",
      streetId: "",
      areaType: "",
      parkData: [],
      // 折线图样式
      type3: "c3",
      type2: "c2",
      areaList: [],
      parkingList: [],
      streetList: [],
      berthInfo: { berthTime: 0, berthRate: 0, parkTime: 0 },
      temParkData: {},
      modelvalue: "",
      tableData: [],
      indicators: [],
      analysis: {},
      ranking: [],
      pName: "",
      index: 0,
      page: 1,
      startDate: "",
      endDate: "",
      pageSize: 15,
      total: 0,
      loading: false,
      detailFlag: false,
      formInline: {
        dateType: "day",
        // date1: dateFormat(startTime, "yyyy-MM"),
        // date2: dateFormat(endTime, "yyyy-MM"),
        // day1: dateFormat(startTime, "yyyy-MM-dd"),
        // day2: dateFormat(endTime, "yyyy-MM-dd"),
        startTime: "",
        endTime: "",
        parkId: "",
      },
      desc: "",
    };
  },
  filters: {
    formatDuringFilter(val) {
      if (val) {
        let a = val.toFixed(2);
        let res = moment.duration(a, "seconds").format("HH:mm");
        let str = "";
        if (res.split(":")[1]) {
          str = `${res.split(":")[0]}时${res.split(":")[1]}分`;
        } else {
          str = `${res.split(":")[0]}分`;
        }

        return str;
      } else {
        return `00时00分`;
      }

      // return formatDuring(val, "seconds");
    },
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    clearPark() {},
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },

    exportFileList() {
      const tempData = {
        // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        parkId: this.formInline.parkId,
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      if (this.desc === 0 || this.desc === 1) {
        tempData.desc = this.desc;
      }
      const url = "/acb/2.0/parkingAnalysis/exportParkingResourceRanking";
      // this.chartDateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : ""
      exportExcelNew(url, tempData);
    },
    empty() {
      this.modelvalue = "";
      this.areaId = "";
      this.streetId = "";
      this.streetList = [];
      this.formInline.dateType = "day";
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
    },
    // 资源请求数据排行
    getranking(val, desc) {
      this.chartDateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      let url = "/acb/2.0/parkingAnalysis/parkingResourceRanking";
      const tempData = {
        areaType: val,
        streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        parkId: this.formInline.parkId,
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      if (desc === 0 || desc === 1) {
        this.desc = desc;
        tempData.desc = desc;
      }
      this.$axios
        .post(url, {
          data: tempData,
        })
        .then((res) => {
          if (res.state == 0) {
            this.ranking = res.value.ranking;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (res.value.depth == 3) {
            this.areaList = res.value.areaList;
          } else {
            this.streetList = res.value.areaList;
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    areaChange(areaId) {
      this.parkPage = 1;
      this.streetList = [];
      this.streetId = "";
      this.setParkNull();
      if (!areaId) return;
      this.getStreet(areaId);
    },
    streetChange(streetId) {
      this.parkPage = 1;
      this.setParkNull();
      this.getPark(streetId);
    },
    // 请求区域数据
    getStreet(areaId) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: {
            areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.streetList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getPark(areaId) {
      this.area = areaId;
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: this.parkPage,
            pageSize: this.pageSize,
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (this.parkPage == 1) {
              this.$refs.select.setNull();
            }
            this.parkData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // getTimeNow() {
    //   const startTime = new Date();
    //   const endTime = new Date();
    //   if (this.formInline.dateType == "day") {
    //     startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    //     endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    //   } else {
    //     startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
    //     endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
    //   }
    //   this.formInline.date1 = dateFormat(startTime, "yyyy-MM");
    //   this.formInline.date2 = dateFormat(endTime, "yyyy-MM");
    // },
    querySearchAsync(queryString, cb) {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
            areaIds: areaId,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.dateType === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    getData() {
      if (this.activeName === "first") {
        this.activeName = "firstq";
      } else {
        this.activeName = "first";
      }
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      this.chartDateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "";
      // let flag = this.showLog();
      // if (!flag) return;
      let url = "/acb/2.0/parkingAnalysis/overview";
      this.loading = true;
      this.$axios
        .post(url, {
          data: {
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            // streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.formInline.parkId,
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
          },
        })
        .then((res) => {
          this.rankingNumber = 2;
          this.loading = false;
          if (res.value.trend.length !== 0) {
            // 折线图数据
            this.tableData = res.value.trend;
          } else {
            this.tableData = [];
          }
          if (res.value.indicators) {
            // 统计数据
            this.indicators = res.value.indicators;
          } else {
            this.indicators = [];
          }
          if (res.value.analysis) {
            // 资源分析
            this.analysis = res.value.analysis;
          } else {
            this.analysis = null;
          }
          if (res.value.ranking.length !== 0) {
            // 资源排行
            this.ranking = res.value.ranking;
          } else {
            this.ranking = [];
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    exportFile() {
      let url = "/acop/2.0/financeStatistic/parks/report/export";
      // let flag = this.showLog();
      // if (!flag) return;
      // this.chartDateType = this.$refs.datePicker ? this.$refs.datePicker.getDateType() : ""
      let opt = {};
      opt = {
        parkId: this.formInline.parkId,
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        timeType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      exportExcelNew(url, opt);
    },
    receiveData(data) {
      this.formInline.parkId = data.value.parkId;
    },
  },
  beforeDestroy() {},
  components: {
    CircularChart,
    rankingList,
    pieChart,
    // timeRangePick,
    graphTitle,
    ParkingChartWeek,
    ParkingChartMonth,
  },
  created() {
    // this.getParkList();
    // this.getTimeNow();
    this.getAreaList();
  },
  mounted() {
    this.getData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">

.parkStatisticbg1 {
  background: #68ce62;
}

.parkStatisticbg2 {
  background: #e6a23c;
}

.parkStatisticbg3 {
  background: #3a8ee6;
}

.parkStatisticbg4 {
  background: #FF3300;
}

.parkStatistic {
  width: 150px;
  height: 150px;
  line-height: 150px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  margin-bottom: 40px;
  color: #fff;

  span {
    font-size: 25px;
  }
}

.content {
  overflow: hidden;

  .statistical {
    background: #fff;
    padding-right: 20px;

    .circular {
      // margin-bottom: 20px;
      .el-col {
        text-align: center;

        .grid-content {
          padding-top: 15px;
          height: 100%;
        }

        h2 {
          font-size: 14px;
          color: #8687a5;
          text-align: center;
          padding-bottom: 20px;
        }
      }
    }
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
